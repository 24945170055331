import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalState from "./modules/GlobalState";
import Landing from "./modules/Landing/";
import Work from './modules/Work/Work';
import CaseStudy from './modules/CaseStudy/CaseStudy';
import Talent from "./modules/Talent/";
import Contact from "./modules/Contact/";
import Navigation from "./modules/Navigation/";
import { QueryParamProvider } from 'use-query-params';

import "./styles.scss";

const App = () => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <GlobalState>
          <Navigation />
          <Switch>
            <Route path="/work/:url">
              <CaseStudy />
            </Route>
            <Route path="/work">
              <Work />
            </Route>
            <Route path="/talent">
              <Talent />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Landing />
            </Route>
          </Switch>
        </GlobalState>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
