import { gsap } from "gsap";

const __intro = (isMobile) => {
  if (isMobile) return gsap.timeline({ autoPlay: false });
  return gsap
    .timeline({ autoPlay: false })

    .to(".caveat-talent-lightBox", {
      clipPath: "inset(0% 0% 0% 0%)",
      backgroundColor: "white",

      duration: 0.3,
      ease: "expo.inOut",
    })
    .to(
      ".caveat-item-container",
      {
        opacity: 1,
        top: 0,
        duration: 1,
        ease: "expo.out",
      },
      "-=.2"
    )
    .to(
      ".caveat-talent-lightbox-title-lockup",
      {
        clipPath: "inset(0% 0% 0% 0%)",
        duration: 0.4,
        borderLeftWidth: 0,
        ease: "expo.inOut",
      },
      "-=.4"
    )
    .to(
      ".caveat-talent-lightbox-title-lockup h1, .caveat-talent-lightbox-title-lockup h3",
      {
        borderWidth: 0,
        stagger: 0.1,
        duration: 0.6,
        ease: "expo.inOut",
      },
      "-=.6"
    )
    .to(
      ".caveat-talent-lightbox-title-lockup h1, .caveat-talent-lightbox-title-lockup h3",
      {
        backgroundColor: "rgba(255,255,255,0)",
        stagger: 0.1,
        duration: 1,
        ease: "expo.inOut",
      },
      "-=.6"
    )
    .pause();
};

const __mediaShow = (isMobile) => {
  if (isMobile) return gsap.timeline({ autoPlay: false });
  return gsap
    .timeline({ autoPlay: false })
    .to(".caveat-item-container img", {
      opacity: 1,
      duration: 1,
    })
    .pause();
};
const __hide = (isMobile) => {
  if (isMobile) return gsap.timeline({ autoPlay: false });
  return gsap
    .timeline({ autoPlay: false })
    .to(".caveat-item-container", {
      opacity: 0,
      top: "10vh",
      duration: 0.3,
      ease: "expo.out",
    })
    .to(
      ".caveat-talent-lightBox",
      {
        clipPath: "inset(100% 0% 0% 0%)",
        backgroundColor: "white",
        rotate: 0,
        duration: 0.8,
        ease: "expo.inOut",
      },
      "-=.2"
    )

    .pause();
};
const __reset = () => {
  console.log("internal reset");
  // gsap.set(".anim", {
  //   clearProps: "all",
  // });
};

const animations = () => {
  let intro, mediaShow, hide;

  const reset = (isMobile) => {
    __reset(isMobile);
    __init(isMobile);
  };

  const __createTimeLines = (isMobile) => {
    return {
      intro: (intro = __intro(isMobile)),
      mediaShow: (mediaShow = __mediaShow(isMobile)),
      hide: (hide = __hide(isMobile)),
    };
  };

  const __init = (isMobile) => {
    if (intro) intro.invalidate();
    if (mediaShow) mediaShow.invalidate();
    if (hide) hide.invalidate();
    return __createTimeLines(isMobile);
  };

  return {
    init: __init,
  };
};

export default animations;
