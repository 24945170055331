import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

export const Logo = (props) => (
  <svg {...props} className="caveat-logo-svg" viewBox="0 0 288 107.14">
    <polygon points="141.08,13.89 133.04,13.89 127.22,50.67 134.61,50.67 		" />
    <path
      d="M22.86,32.67c0-7.37,2.67-12.91,8.77-12.91c6.75,0,8.35,5.75,8.35,12.28v3.43h7.7v-2.99
        c0-10.25-3.42-19.61-16.36-19.61c-9.95,0-16.17,7.62-16.17,19.96v17.85h7.71V32.67z"
    />
    <path
      d="M15.15,73.27c-0.2,10.66,3.31,21.02,16.09,21.02c13.78,0,16.8-10.65,16.8-20.98v-5.31H40.3v5.79
        c0,7.84-1.79,13.65-8.39,13.65c-6.27,0-9.04-5.12-9.04-13.74V60.04h-7.71V73.27z"
    />
    <polygon points="77.2,21.64 81.76,50.67 88.93,50.67 82.6,13.89 71.81,13.89 65.74,50.67 72.99,50.67 			" />
    <polygon
      points="58.72,93.25 66.35,93.25 70.58,66.96 84.21,66.96 88.33,93.25 96.25,93.25 90.54,60.04 64.2,60.04 
              "
    />
    <polygon
      points="193.01,93.25 200.65,93.25 204.88,66.96 218.5,66.96 222.63,93.25 230.54,93.25 224.83,60.04 
        198.49,60.04 			"
    />
    <polygon points="211.49,21.64 216.06,50.67 223.22,50.67 216.9,13.89 206.11,13.89 200.04,50.67 207.28,50.67 			" />
    <polygon points="111.9,13.89 103.74,13.89 110.15,50.67 117.77,50.67 			" />
    <polygon
      points="122.51,80.42 119.26,60.04 111.78,60.04 117.56,93.25 127.12,93.25 132.96,60.04 125.74,60.04 			
        "
    />
    <polygon points="160.1,20.88 179.01,20.88 179.01,13.89 152.55,13.89 152.55,50.67 160.1,50.67 			" />
    <polygon points="152.55,93.25 180.35,93.25 180.33,86.31 160.1,86.31 160.1,60.04 152.55,60.04 			" />
    <polygon
      points="272.86,20.88 272.86,13.89 239.69,13.89 239.69,20.88 252.55,20.88 252.55,50.67 260.1,50.67 
        260.1,20.88 			"
    />
    <rect x="252.55" y="60.04" width="7.55" height="33.21" />
  </svg>
);

export const ArrowSVG = ({ height, id }) => (
  <svg id={id} className="caveat-arrow" viewBox={`0 0 100 ${height}`}>
    <g>
      <rect y="10" x="49" width="2" height="1000" />
      <polygon points="50 0, 55 10, 45 10" />
    </g>
  </svg>
);

export const SimpleLogo = (props) => (
  <svg {...props} className="caveat-small-logo-svg" viewBox="0 0 76 128">
    <path
      d="M38.2,3.93L38.2,3.93c-18.59,0-33.8,15.21-33.8,33.8v53.1c0,18.59,15.21,33.8,33.8,33.8h0c18.59,0,33.8-15.21,33.8-33.8
	v-53.1C72,19.14,56.79,3.93,38.2,3.93z M52.96,82.15c0,9.41-2.71,19.13-15.09,19.13c-11.48,0-14.63-9.44-14.45-19.16V70.05h6.93
	V82.5c0,7.85,2.49,12.52,8.12,12.52c5.93,0,7.54-5.3,7.54-12.45v-6.46h6.96V82.15z M54.94,59.37l-6.6-0.8v0.09l4.19,5.32l-2.75,1.6
	l-2.62-6.21h-0.09l-2.84,6.25l-2.49-1.6l4.15-5.41v-0.09l-6.46,0.84v-3.01l6.42,0.84v-0.09l-4.11-5.41l2.66-1.55l2.71,6.16h0.09
	l2.66-6.21l2.62,1.51l-4.15,5.54v0.09l6.6-0.89V59.37z"
    />
  </svg>
);

export const PhoneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M72.78,59.48c-1.25-.62-7.38-3.64-8.53-4s-2-.63-2.8.62-3.22,4.06-4,4.89-1.46.94-2.7.31a34,34,0,0,1-10-6.19,37.65,37.65,0,0,1-6.94-8.64c-.72-1.25,0-1.86.55-2.55a35.53,35.53,0,0,0,3.12-4.26,2.27,2.27,0,0,0-.11-2.19c-.31-.62-2.8-6.76-3.84-9.26s-2-2.1-2.81-2.14S33.17,26,32.34,26A4.64,4.64,0,0,0,29,27.53a14,14,0,0,0-4.36,10.41c0,6.14,4.47,12.07,5.09,12.91s8.8,13.43,21.31,18.83a69.9,69.9,0,0,0,7.11,2.63,17.13,17.13,0,0,0,7.86.5c2.4-.36,7.38-3,8.42-5.94s1-5.41.73-5.93S74,60.11,72.78,59.48ZM85.15,0H14.85A14.85,14.85,0,0,0,0,14.85v70.3A14.85,14.85,0,0,0,14.85,100h70.3A14.85,14.85,0,0,0,100,85.15V14.85A14.85,14.85,0,0,0,85.15,0Zm8,85.15a8,8,0,0,1-8,8H14.85a8,8,0,0,1-8-8V14.85a8,8,0,0,1,8-8h70.3a8,8,0,0,1,8,8Z"/></g></g>
  </svg>
);

export const MailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M85.15,0H14.85A14.85,14.85,0,0,0,0,14.85v70.3A14.85,14.85,0,0,0,14.85,100h70.3A14.85,14.85,0,0,0,100,85.15V14.85A14.85,14.85,0,0,0,85.15,0ZM6.85,29.82,29.59,48.33,6.85,70.41Zm86.3,55.33a8,8,0,0,1-8,8H14.85a8,8,0,0,1-8-8V80L35,52.68,50,64.93,65.28,52.49,93.15,80Zm0-14.81L70.63,48.14,93.15,29.82Zm0-49.36L50,56.1,6.85,21V14.85a8,8,0,0,1,8-8h70.3a8,8,0,0,1,8,8Z"/></g></g>
  </svg>
);

export const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M84.86,100h-21V65.53H75.56l2-8.37H63.91V42c0-5.71,4.82-7.74,9.33-7.74h2.29V24.71H70A17,17,0,0,0,53.08,41.65V57.16H41.46v8.37H53.08V100H15.14A15.15,15.15,0,0,1,0,84.86V15.14A15.15,15.15,0,0,1,15.14,0H84.86A15.15,15.15,0,0,1,100,15.14V84.86A15.15,15.15,0,0,1,84.86,100ZM71,93H84.86A8.12,8.12,0,0,0,93,84.86V15.14A8.12,8.12,0,0,0,84.86,7H15.14A8.12,8.12,0,0,0,7,15.14V84.86A8.12,8.12,0,0,0,15.14,93H46V72.56H34.42V50.12H46V41.65a24,24,0,0,1,24-24H82.57V41.31H73.24A4.36,4.36,0,0,0,71,41.7,1.51,1.51,0,0,0,71,42v8.1H86.39L81.13,72.56H71Z"/></g></g>
  </svg>
);

export const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M69.09,0H30.91A30.91,30.91,0,0,0,0,30.91V69.09A30.91,30.91,0,0,0,30.91,100H69.09A30.91,30.91,0,0,0,100,69.09V30.91A30.91,30.91,0,0,0,69.09,0ZM93.15,69.09A24.08,24.08,0,0,1,69.09,93.15H30.91A24.08,24.08,0,0,1,6.85,69.09V30.91A24.08,24.08,0,0,1,30.91,6.85H69.09A24.08,24.08,0,0,1,93.15,30.91ZM76.88,19.79a3.34,3.34,0,1,0,3.33,3.33A3.34,3.34,0,0,0,76.88,19.79ZM50,25A25,25,0,1,0,75,50,25,25,0,0,0,50,25Zm0,43.15A18.15,18.15,0,1,1,68.15,50,18.17,18.17,0,0,1,50,68.15Z"/></g></g>
  </svg>
);

export const LinkedinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M23.05,14a9.48,9.48,0,0,0-9.33,9.63A9.48,9.48,0,0,0,23,33.22a9.48,9.48,0,0,0,9.33-9.63h0A9.5,9.5,0,0,0,23.05,14ZM30.05,37.1h-14a1.23,1.23,0,0,0-1.21,1.25h0V84.9a1.22,1.22,0,0,0,1.21,1.24h14a1.22,1.22,0,0,0,1.21-1.24V38.34A1.23,1.23,0,0,0,30.05,37.1ZM65.86,36.55c-5.13,0-9.63,1.61-12.37,4.24V38.34a1.23,1.23,0,0,0-1.21-1.24H38.84a1.22,1.22,0,0,0-1.21,1.24h0V84.9a1.22,1.22,0,0,0,1.21,1.24h14A1.22,1.22,0,0,0,54,84.9V61.85c0-6.61,1.17-10.7,7-10.7s6.22,4.4,6.22,11.1V84.89a1.23,1.23,0,0,0,1.2,1.25h14a1.23,1.23,0,0,0,1.21-1.25V59.35C83.72,48.74,81.69,36.55,65.86,36.55Z"/></g></g>
  </svg>

);

ArrowSVG.propTypes = { height: PropTypes.number, id: PropTypes.string };
