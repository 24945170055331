/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SimpleLogo } from "../Graphics";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import useGlobal from "../../store";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  COPY_WEEKLY_VARIENTS,
  GET_OVERRIDE_COPY,
  GET_DEFAULT_COPY,
} from "../../queries";
import { getDayTime } from "../../utils";

let lastPath = "";
ReactGA.initialize("UA-166494877-1");
// const mobileMatch = window.matchMedia("only screen and (max-width: 575.98px)");
const mobileMatch = window.matchMedia("(orientation: portrait)");

const GlobalState = ({ children }) => {
  const [globalState, globalActions] = useGlobal();
  const history = useHistory();

  history.listen((location) => {
    if (location.pathname !== lastPath) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
    lastPath = location.pathname;
  });

  useEffect(() => {
    if (!globalState.firstLoad) {
      ReactGA.set({ page: history.location.pathname });
      ReactGA.pageview(history.location.pathname);
      globalActions.setFirstLoad(true);
    }
  }, [globalState.firstLoad, globalActions, history.location.pathname]);

  const setResponsive = (e) => {
    globalActions.setIsMobile(e.matches);
  };

  if (!globalState.firstLoad) {
    mobileMatch.addListener(setResponsive);

    globalActions.setIsMobile(mobileMatch.matches);
  }
  let loading, error, data;

  const { day, timeOfDay } = getDayTime();
  const { loading: overLoading, error: overError, data: overData } = useQuery(
    GET_OVERRIDE_COPY
  );
  const { loading: defLoading, error: defError, data: defData } = useQuery(
    GET_DEFAULT_COPY
  );
  const { loading: regLoading, error: regError, data: regData } = useQuery(
    COPY_WEEKLY_VARIENTS(day, timeOfDay)
  );

  loading = overLoading || regLoading || defLoading;
  error = overError || regError || defError;

  if (loading)
    return (
      <section className="caveat-section">
        <div className="caveat-data-loading">
          <SimpleLogo />
        </div>{" "}
      </section>
    );
  if (error)
    return (
      <section className="caveat-section">
        <div className="ERROR">{error.message}</div>{" "}
      </section>
    );
  if (overData.allCopyDocuments.length > 0) {
    data = overData.allCopyDocuments[0];
  } else {
    data = regData.allWeeklySchedules[0][day][timeOfDay][0];
  }
  globalActions.setCurrentCopy({
    copy: data,
    fallback: defData.allCopyDocuments[0],
  });
  return <React.Fragment>{children}</React.Fragment>;
};
GlobalState.propTypes = {
  children: PropTypes.array,
};

export default GlobalState;
