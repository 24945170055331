import './SimpleContact.scss';

import React from 'react';
import {
    Link,
} from 'react-router-dom';
import b_ from 'b_';

import useGlobal from 'src/store';

const b = b_.with('simple-contact');

export default function SimpleContact() {
    const [globalStore] = useGlobal();
    const {
        smallContactHeadline: contactHeadline,
        smallContactImperitive: contactImperitive,
    } = globalStore.currentCopy;

    return (
        <div
            className={b()}
            itemScope
            itemType="http://schema.org/LocalBusiness"
        >
            <div className={b('headline')}>
                <h1>{contactHeadline}</h1>
                <h2>{contactImperitive}</h2>
            </div>
            <div className={b('info')}>
                <span className={b('field', {contact: true})}>
                    <Link to="/contact">Contact Page</Link>
                </span>
                <span
                    className={b('field', {hidden: true})}
                    itemProp="name"
                >
                    Caveat
                </span>
                <span
                    className={b('field')}
                    itemProp="telephone"
                >
                    <a href="tel:+01-833-422-8328">833-422-8328</a>
                </span>
                <span
                    className={b('field')}
                    itemProp="email"
                >
                    <a href="mailto:hello@hellocaveat.com">hellocaveat.com</a>
                </span>
            </div>
        </div>
    )
}
