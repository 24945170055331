import './Pagination.scss';

import React, {
    useMemo,
} from 'react';
import {
    useHistory,
    useParams,
} from 'react-router-dom';
import {
    useQuery,
} from '@apollo/client';
import b_ from 'b_';

import {
    PROJECTS,
} from 'src/queries';

import {
    SimpleLogo,
} from 'src/modules/Graphics';

const b = b_.with('pagination');

export default function Pagination() {
    const history = useHistory();
    const {
        url,
    } = useParams();
    const {
        loading,
        error,
        data,
    } = useQuery(PROJECTS);

    const [
        prev,
        next,
    ] = useMemo(() => {
        if (!data?.allCaseStudies) {
            return [
                null,
                null,
            ];
        }

        const current = data.allCaseStudies.findIndex((caseStudy: any) => caseStudy.url === url);
        return [
            data.allCaseStudies[current - 1] || null,
            data.allCaseStudies[current + 1] || null,
        ];
    }, [
        url,
        data,
    ]);

    const navigateWorkItem = (url: string) => {
        return history.push(`/work/${url}`);
    }

    if (loading)
        return (
            <section className="caveat-section">
                <div className="caveat-data-loading">
                    <SimpleLogo />
                </div>
            </section>
        );

    if (error)
        return (
            <div>
                {error.message}
            </div>
        );

    return (
        <div className={b()}>
            <div className={b('prev')}>
                {prev && (
                    <>
                        <img
                            className={b('preview')}
                            src={prev.projectPreview.publicUrl}
                        />
                        <div
                            className={b('navigation')}
                            onClick={() => navigateWorkItem(prev.url)}
                        >
                            <span className={b('project-name')}>
                                {prev.projectName}
                            </span>
                            <span className={b('sub-name')}>
                                {prev.clientName}
                            </span>
                        </div>
                    </>
                )}
            </div>
            <div className={b('next')}>
                {next && (
                    <>
                        <img
                            className={b('preview')}
                            src={next.projectPreview.publicUrl}
                        />
                        <div
                            className={b('navigation')}
                            onClick={() => navigateWorkItem(next.url)}
                        >
                            <span className={b('project-name')}>
                                {next.projectName}
                            </span>
                            <span className={b('sub-name')}>
                                {next.clientName}
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}