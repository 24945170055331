import './SidePanel.scss';

import React, {
    useRef,
    useState,
} from 'react';
import b_ from 'b_';

import {
    useOutsideClick,
} from 'src/hooks/useOutsideClick';

const b = b_.with('side-panel');

interface IProps {
    title: string;
    description: string;
    tags: string;
}

export default function SidePanel({
    title,
    description,
    tags,
}: IProps) {
    const ref = useRef(null);
    const [
        expanded,
        setExpanded,
    ] = useState(false);

    useOutsideClick(ref, () => setExpanded(false))

    return (
        <div
            className={b()}
            ref={ref}
        >
            <div
                className={b('tag')}
                onClick={() => setExpanded(prev => !prev)}
            >
                <span className={b('arrow', {expanded})}/>
                <div className={b('label', {expanded})}>tl;dr</div>
            </div>
            <div className={b('content', {expanded})}>
                <span className={b('title')}>{title}</span>
                <span className={b('description')}>{description}</span>
                <span className={b('tags')}>{tags}</span>
            </div>
        </div>
    )
}