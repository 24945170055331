import { gql } from "@apollo/client";

export const PROJECTS = gql`
  {
    allCaseStudies(orderBy: "order_ASC", where: { visible: true }) {
      projectName
      clientName
      subHead
      url
      projectPreview {
        publicUrl
      }
      projectLogo {
        publicUrl
      }
    }
  }
`;

export const ADD_CONTACT = gql`
  mutation CreateContact(
    $email: String!
    $fullName: String!
    $inquiryType: String!
    $phoneNumber: String!
  ) {
    createContact(
      data: {
        email: $email
        fullName: $fullName
        inquiryType: $inquiryType
        phoneNumber: $phoneNumber
      }
    ) {
      id
      email
      fullName
      inquiryType
      phoneNumber
    }
  }
`;

export const PROJECT_BY_URL = gql`
  query ProjectByURL($url: String!) {
    allCaseStudies(where: { visible: true, url: $url }) {
      projectName
      subHead
      siteUrl
      projectType {
        type
      }
      clientCategory {
        type
      }
      year
      projectLogoBackgroundColor
      mediaBackgroundColor
      projectLogo {
        publicUrl
      }
      projectPreview {
        publicUrl
      }
      isSidePanelVisible
      sidePanelTitle
      sidePanelDescription
      contentBlocks(orderBy: "order_ASC") {
        order
        bodyCopy: description
        largeHeadline: descriptionHeading
        imagesPerRow
        vimeoId
        mediumHeadline
        quote
        images(orderBy: "order_ASC") {
          name
          height
          width
          order
          image {
            publicUrl
          }
          vimeoId
        }
      }
    }
  }
`;

export const ALL_CREATOR_FILTERS = gql`
  query getAllCreatorFilters {
    allCreativeMediaTypes(orderBy: "type_ASC") {
      _label_
      __typename
      id
    }
    allCreativeIndustries(orderBy: "industry_ASC") {
      _label_
      __typename
      id
    }
    allCreativeStyles(orderBy: "style_ASC") {
      _label_
      __typename
      id
    }
    allCreators(orderBy: "firstName") {
      lastName
      firstName
      __typename
      id
    }
  }
`;

export const ALL_CREATORS = gql`
  query getAllCreatorProjects {
    allCreatorProjects(orderBy: "title_ASC") {
      title
      thumbnail {
        publicUrl
      }
      media {
        vimeoId
        image {
          publicUrl
        }
      }
      mediaTypes {
        id
        _label_
      }
      industries {
        id
        _label_
      }
      styles {
        id
        _label_
      }
      creator {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ALL_NEWS_ITEMS = gql`
  query getNewsItems {
    allNewsItems(where: { visible: true }) {
      media {
        publicUrl
      }
      url
      urlText
      vimeoId
      bodyCopy
      headline
      landingCurrentCTA
      landingCurrentHeading
      publishDate
      visible
    }
  }
`;

export const GET_DEFAULT_COPY = gql`
  query getDefaultCopty {
    allCopyDocuments(where: { default: true, visible: true }) {
      label
      landingHeading
      landingSubHeading
      landingVideo {
        publicUrl
      }
      navigationWorkCTA
      navigationTalentCTA
      navigationContactCTA
      navigationMinimartCTA
      workHeading
      workSubHeading
      talentHeading
      talentSubHeading
      contactHeading
      contactSubHeading
      smallContactHeadline
      smallContactImperitive
      contactFeedbackHeading
      contactFeedbackSubHeading
      isMinimartEnabled
    }
  }
`;

export const GET_OVERRIDE_COPY = gql`
  query getDefaultCopty {
    allCopyDocuments(where: { overrideTime: true, visible: true }) {
      label
      landingHeading
      landingSubHeading
      landingVideo {
        publicUrl
      }
      navigationWorkCTA
      navigationTalentCTA
      navigationContactCTA
      workHeading
      workSubHeading
      talentHeading
      talentSubHeading
      contactHeading
      contactSubHeading
      smallContactHeadline
      smallContactImperitive
      contactFeedbackHeading
      contactFeedbackSubHeading
    }
  }
`;

export const COPY_WEEKLY_VARIENTS = (day, timeOfDay) => gql`
query getWeeklyCopyTiming{
  allWeeklySchedules {
    ${day} {
      ${timeOfDay} {
        label
        landingHeading
        landingSubHeading
        landingVideo{
          publicUrl
        }
        navigationWorkCTA
        navigationTalentCTA
        navigationContactCTA
        workHeading
        workSubHeading
        talentHeading
        talentSubHeading
        contactHeading
        contactSubHeading
        smallContactHeadline
        smallContactImperitive
        contactFeedbackHeading
        contactFeedbackSubHeading
      }
    }
  }
}`;
