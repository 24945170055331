import React, { useEffect } from "react";
import useGlobal from "../../store";
import { NavLink, Link } from "react-router-dom";
import { gsap } from "gsap";
import { colors } from "../../vars";

// ----------------------------
import "./styles.scss";
// ----------------------------
import { Logo } from "../Graphics/";

const SOURCE_Z_INDEX = 1000;

const Navigation = () => {
  const [{ isMobile, currentCopy }] = useGlobal();

  let menuOpen = false;
  let menuElm;
  let navElem;
  let x;
  let h;

  const onResize = () => {
    if (navElem) {
      navElem.style.height = `${window.innerHeight}px`;
    }
  };

  let {
    navigationWorkCTA,
    navigationTalentCTA,
    navigationContactCTA,
    isMinimartEnabled
  } = currentCopy;

  let borderWidth = isMobile ? "1vh" : "0.5vw";
  let borderColor = isMobile ? colors.purple : colors.blue;

  useEffect(() => {
    // if (navElem) {
    //   onResize();
    //   window.addEventListener("resize", onResize, { passive: true });
    //   navElem.style.borderWidth = borderWidth;
    //   return () => {
    //     window.removeEventListener("resize", onResize);
    //   };
    // }
    window.scrollTo(0, 0);
    navElem.style.borderWidth = borderWidth;
  }, [isMobile]);

  const showMenu = () => {
    console.log("open");
    gsap.to(navElem, {
      duration: 0.7,
      borderRightWidth: "100vw",
      borderColor: "white",
      ease: "expo.inOut",
    });

    gsap.to(menuElm, {
      duration: 0.2,
      top: "0",
      delay: 0.5,
      ease: "expo.inOut",
      onComplete: () => {
        x.style.opacity = menuOpen ? 1 : 0;
        h.style.opacity = menuOpen ? 0 : 1;
      },
    });

    navElem.style.zIndex = SOURCE_Z_INDEX * 2;
    menuElm.style.display = "block";
    menuOpen = true;
  };

  const hideMenu = () => {
    console.log("close");
    gsap.to(navElem, {
      duration: 0.7,
      borderWidth: borderWidth,
      borderColor: borderColor,
      delay: 0.2,
      ease: "expo.inOut",
    });

    gsap.to(menuElm, {
      duration: 0.5,
      top: "-100vh",
      ease: "expo.inOut",
      onComplete: () => {
        menuElm.style.display = "none";
        navElem.style.zIndex = SOURCE_Z_INDEX;
        x.style.opacity = menuOpen ? 1 : 0;
        h.style.opacity = menuOpen ? 0 : 1;
      },
    });
    menuOpen = false;
  };

  const toggleMenu = () => {
    if (!menuOpen) {
      showMenu();
    } else {
      hideMenu();
    }
  };

  return (
    <nav ref={(e) => (navElem = e)} className="caveat-navigation --nav">
      <div id="naviation-logo" className="caveat-navigation-logo" onClick={hideMenu}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <svg
        className="caveat-navigation-indicator"
        onClick={toggleMenu}
        viewBox={`0 0 150 150`}
      >
        <g ref={(element) => (x = element)} className="navigation-x">
          <rect
            x="0"
            y="60.5"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 75 -31.066)"
            width="150"
            height="29"
          />
          <rect
            x="0"
            y="60.5"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -31.066 75)"
            width="150"
            height="29"
          />
        </g>
        <g ref={(element) => (h = element)} className="navigtion-h">
          <rect
            className="nav-rect"
            x="0"
            y="30"
            transform=""
            width="150"
            height="29"
          />
          <rect
            className="nav-rect"
            x="0"
            y="90"
            transform=""
            width="150"
            height="29"
          />
        </g>
      </svg>
      <div
        onClick={toggleMenu}
        ref={(e) => (menuElm = e)}
        id="--menu-modal"
        className="caveat-navigation-modal"
      >
        <ul>
          <li>
            <NavLink to="/work">{navigationWorkCTA}</NavLink>
          </li>
          <li>
            <NavLink to="/talent">{navigationTalentCTA}</NavLink>
          </li>
          <li>
            <NavLink to="/contact">{navigationContactCTA}</NavLink>
          </li>
        </ul>
        {isMinimartEnabled && <a className="caveat-navigation-modal-minimart" target="_blank" rel="noreferrer" href="https://minimart.hellocaveat.com"></a>}
      </div>
    </nav>
  );
};
export default Navigation;
