import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowSVG, PhoneIcon, MailIcon, FacebookIcon, InstagramIcon, LinkedinIcon } from "../Graphics/";
import useGlobal from "../../store";
import { ADD_CONTACT } from "../../queries";
import { useMutation } from "@apollo/client";
import { colors } from "../../vars";
import "./styles.scss";

// ************************************************************
// * Contact Form Feedback Component
// ************************************************************
const ContactFormFeedback = () => {
  const [{ currentCopy }] = useGlobal();
  const { contactFeedbackHeading, contactFeedbackSubHeading } = currentCopy;
  return (
    <section id="contact-section" className="caveat-section">
      <form>
        <div id="contact-main-container">
          <div className="contact-main-title-lockup">
            <h1>{contactFeedbackHeading}</h1>
            <h2>{contactFeedbackSubHeading}</h2>
          </div>
        </div>
      </form>
    </section>
  );
};
// ************************************************************
// * Contact Form Component
// ************************************************************
const ContactForm = ({ onSubmit }) => {
  const [{ isMobile, currentCopy }] = useGlobal();
  const { contactHeading, contactSubHeading } = currentCopy;
  const onBlur = (e) => window.scrollTo(0, 0);

  useEffect(() => {
    if (isMobile) {
      Array.from(document.getElementsByTagName("input")).map((el) => {
        el.addEventListener("blur", onBlur);
        return el;
      });
      Array.from(document.getElementsByTagName("select")).map((el) => {
        el.addEventListener("blur", onBlur);
        return el;
      });
    }

    return () => {
      Array.from(document.getElementsByTagName("input")).map((el) => {
        el.removeEventListener("blur", onBlur);
        return el;
      });
      Array.from(document.getElementsByTagName("select")).map((el) => {
        el.removeEventListener("blur", onBlur);
        return el;
      });
    };
  }, [isMobile]);

  return (
    <section id="contact-section" className="caveat-section">
      <form onSubmit={onSubmit} method="post">
        <div id="contact-main-container">
          <div className="contact-main-title-lockup">
            <h1>{contactHeading}</h1>
            <h2>{contactSubHeading}</h2>
          </div>

          <div className="caveat-contact-form-label">
            <label>
              <h3>I am</h3>
            </label>
          </div>
          <div className="caveat-contact-input">
            <input
              name="fullName"
              placeholder="Your Name"
              type="text"
              required={true}
            />
          </div>
          <div className="caveat-contact-form-label">
            <label>
              <h3>My Deets</h3>
            </label>
          </div>
          <div className="caveat-contact-input">
            <input name="phoneNumber" placeholder="Your Number" type="tel" />
          </div>
          <div className="caveat-contact-form-label">
            <label>
              <h3>I Need</h3>
            </label>
          </div>
          <div className="caveat-contact-input">
            <select name="inquiryType">
              <option value="A hug">A hug</option>
              <option value="Alcohol">Alcohol</option>
              <option value="Branding">Branding</option>
              <option value="Content">Creative</option>
              <option value="Drugs">Drugs</option>
              <option value="Everything">Everything</option>
              <option value="Innovation">Innovation</option>
              <option value="Production">Production</option>
              <option value="Talent">Talent</option>
              <option value="Therapy">Therapy</option>
            </select>
          </div>
          <div id="caveat-contact-email-input" className="caveat-contact-input">
            <input
              name="email"
              placeholder="Your Email"
              type="email"
              required={true}
            />
          </div>

          <div className="caveat-contact-submit">
            <button>Send</button>
          </div>

          <div
            itemScope
            itemType="http://schema.org/LocalBusiness"
            className="caveat-contact-info-container"
          >
            <ul className="caveat-contact-info">
              <li>
                <h1 className="invisible" itemProp="name">
                  Caveat
                </h1>
                <span itemProp="telephone">
                  <a href="tel:+01-833-422-8328">
                    <PhoneIcon />
                  </a>
                </span>
              </li>
              <li>
                <span itemProp="facebook">
                  <a
                    href="https://facebook.com/hellocaveat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                </span>
              </li>
              <li>
                <span itemProp="instagram">
                  <a
                    href="https://www.instagram.com/hellocaveat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </span>
              </li>
              <li>
                <span itemProp="linkedin">
                  <a
                    href="https://www.linkedin.com/company/hellocaveat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedinIcon />
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </section>
  );
};
// ************************************************************
// * Contact Component
// ************************************************************
const Contact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [addContact, { loading, error, data }] = useMutation(ADD_CONTACT);
  if (loading) return <div>loading</div>;
  if (error) return <div> {error.message} </div>;
  if (submitted) return <ContactFormFeedback />;

  console.log(data);

  const onSubmit = (e) => {
    const _fd = new FormData(e.target);

    let _sObj = {};
    for (var pair of _fd.entries()) {
      _sObj[pair[0]] = pair[1];
    }
    let today = new Date();
    _sObj.submissionTime = today.toString();
    addContact({ variables: _sObj });

    setSubmitted(true);
    e.preventDefault();
  };

  return <ContactForm onSubmit={onSubmit} />;
};
export default Contact;
// ************************************************************
// * Small Contact Component
// ************************************************************
export const SmallContact = () => {
  const [{ currentCopy }] = useGlobal();
  let { smallContactHeadline, smallContactImperitive } = currentCopy;

  const scrollToTop = () => {
    const workSection = document.getElementById('work-section');
    workSection.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });

    workSection.style.backgroundImage = `linear-gradient(${colors.green}, white)`;
  };

  return (
    <div
      className="caveat-small-contact"
      itemScope
      itemType="http://schema.org/LocalBusiness"
    >
      <h1>{smallContactHeadline}</h1>
      <h2> {smallContactImperitive}</h2>
      <ul className="caveat-contact-info">
        <li>
          <Link to="/contact">Contact Page</Link>
        </li>
        <li>
          <h1 className="invisible" itemProp="name">
            Caveat
          </h1>
          <span itemProp="telephone">
            <a href="tel:+01-833-422-8328">833-422-8328</a>
          </span>
        </li>
        <li>
          <span itemProp="email">
            <a href="mailto:hello@hellocaveat.com">hello@hellocaveat.com</a>
          </span>
        </li>
      </ul>
      <div onClick={scrollToTop} className="caveat-contact-backup-container">
        <h2>Back Up</h2>
        <ArrowSVG height={60}/>
      </div>
    </div>
  );
};
// ************************************************************
