import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider, ApolloClient, InMemoryCache, } from "@apollo/client";
import { getFullPath } from "./utils";
import App from "./App";

const client = new ApolloClient({
    uri: getFullPath(`/admin/api/`),
    cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

navigator.serviceWorker.getRegistrations().then(registrations => {
    for(let registration of registrations) {
        registration.unregister();
    }
});
