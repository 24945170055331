import React, { useEffect } from "react";
import useGlobal from "../../store";
import PropTypes from "prop-types";
import { gsap } from "gsap";
import "./styles.scss";

let allowScroll = true;
let section;

let startY = 0;

const FullPageSection = (props) => {
  const [states, setters] = useGlobal();
  const { children } = props;

  let tcolor = props.gradTop || "white";
  let bcolor = props.gradBottom || "white";

  const getScreenY = (e) => {
    if (e.screenY) return e.screenY;
    if (e.touches[0]) return e.touches[0].screenY;
    return null;
  };
  const getChangedY = (e) => {
    if (e.screenY) return e.screenY;
    if (e.changedTouches[0]) return e.changedTouches[0].screenY;
  };
  const onTouchStart = (e) => {
    startY = getScreenY(e);
  };
  const onTouchEnd = (e) => {
    let diff = Math.abs(startY - getChangedY(e));
    if (diff > 100) animateScroll(startY > getChangedY(e), 0);
  };
  const onWheel = (e) => {
    let { deltaY } = e;

    if (Math.abs(deltaY) < 30) return;
    animateScroll(deltaY > 0, 400);
  };

  const animateScroll = (isUp, delay) => {
    if (!allowScroll || !section) return;

    const val = isUp ? window.innerHeight : -window.innerHeight;
    const scrollTop = section.scrollTop + val;
    const pageCount =
      Array.from(document.getElementsByClassName("--fullPage")).length - 1;
    const nextPage = scrollTop / window.innerHeight;

    if (nextPage > pageCount) return false;

    const atTop = scrollTop <= 0;
    const gradVal = atTop
      ? `linear-gradient(${tcolor}, ${bcolor})`
      : "linear-gradient(white, white)";
    gsap.to(section, {
      duration: 0.3,
      scrollTop: scrollTop,
      backgroundImage: gradVal,
      ease: "expo.inOut",
      onStart: () => {
        allowScroll = false;
        if (props.onPageChangeStart)
          props.onPageChangeStart({
            scrollTop: section.scrollTop,
            page: section.scrollTop / window.innerHeight,
            nextPage: nextPage,
          });
      },
      onComplete: () => {
        setters.setCurrentWorkPage(section.scrollTop / window.innerHeight);
        setTimeout(() => {
          allowScroll = true;
        }, delay);
        if (props.onPageChange)
          props.onPageChange({
            scrollTop: section.scrollTop,
            page: section.scrollTop / window.innerHeight,
          });
      },
    });
  };

  const handlePageUpdate = () => {
    if (states.currentWorkPage !== undefined) {
      if (states.currentWorkPage > 0) {
        section.style.backgroundImage = "linear-gradient(white, white)";
        section.scrollTop = states.currentWorkPage * window.innerHeight;
      }
    }
  };

  useEffect(() => {
    handlePageUpdate();
  }, [states.currentWorkPage]);

  useEffect(() => {
    section.addEventListener("wheel", onWheel, { passive: true });
    section.addEventListener("touchstart", onTouchStart, { passive: true });
    section.addEventListener("touchend", onTouchEnd, { passive: true });
    section.addEventListener("mousedown", onTouchStart, { passive: true });
    section.addEventListener("mouseup", onTouchEnd, { passive: true });
    
    window.addEventListener("rezize", handlePageUpdate);
    return () => {
      if (section) {
        section.removeEventListener("wheel", onWheel, { passive: true });
        section.removeEventListener("touchstart", onTouchStart, {
          passive: true,
        });
        section.removeEventListener("touchend", onTouchEnd, { passive: true });
        section.removeEventListener("mousedown", onTouchStart, {
          passive: true,
        });
        section.removeEventListener("mouseup", onTouchEnd, { passive: true });
        window.removeEventListener("rezize", handlePageUpdate);
      }
    };
  });

  return (
    <section
      ref={(element) => (section = element)}
      id={props.id}
      className="caveat-section"
    >
      {children}
    </section>
  );
};

FullPageSection.propTypes = {
  id: PropTypes.string,
  gradTop: PropTypes.string,
  gradBottom: PropTypes.string,
  children: PropTypes.any,
  onPageChange: PropTypes.func,
  onPageChangeStart: PropTypes.func,
};

export default FullPageSection;
