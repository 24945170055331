export const getDayTime = () => {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const hour = new Date().getHours();
  const day = days[new Date().getDay()];
  let timeOfDay = "lateNight";
  if (hour > 6) timeOfDay = "morning";
  if (hour > 12) timeOfDay = "afterNoon";
  if (hour > 17) timeOfDay = "evening";
  return { day: day, timeOfDay: timeOfDay };
};

export const getFullPath = (_path) => {
  return `https://caveat-admin-dev.herokuapp.com${_path}`;
  //return `https://caveat-admin.herokuapp.com${_path}`;
  //return `http://localhost:3200${_path}`;
};

export function debounce(fn, ms) { 
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
