import {
    useEffect,
} from 'react';
import {
    TweenMax,
} from 'gsap';

import {
    useScrollMagicController,
    Scene,
} from 'src/hooks/useScrollMagic';

import {
    TARGET_ANIMATION,
} from './animation.config';

export const useAnimations = (caseStudy: any) => {
    const controller = useScrollMagicController();

    useEffect(() => {
        const elements = document.getElementsByClassName('new-case-study__under');
        Array.from(elements).forEach((element) => {
            new Scene({
                triggerElement: element,
                triggerHook: 0.5,
                offset: -50,
                reverse: false,
            })
                .on('start', () => TweenMax.to(element, 0.5, TARGET_ANIMATION))
                .addTo(controller);
        });
    }, [
        caseStudy,
    ]);
}