import {
    useRef,
} from 'react';

import ScrollMagic from 'scrollmagic';
import {
    TweenMax,
    TimelineMax,
} from 'gsap';
import {
    ScrollMagicPluginGsap,
} from 'scrollmagic-plugin-gsap';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export const useScrollMagicController = () => {
    const ref = useRef(new ScrollMagic.Controller());

    return ref.current;
}

export const Scene = ScrollMagic.Scene;