import './Work.scss';

import React from 'react';
import {
    useHistory,
} from 'react-router-dom';
import {
    useQuery,
} from '@apollo/client';
import b_ from 'b_';
import {
    gsap,
} from 'gsap';

import useGlobal from 'src/store';

import {
    PROJECTS,
} from 'src/queries';
import {
    colors,
} from 'src/vars';
import {
    SOURCE_ANIMATION,
    TARGET_ANIMATION,
} from './animation.config';

import {
    SimpleLogo,
} from 'src/modules/Graphics';
import FullPageSection from 'src/modules/FullPageSection';
import SimpleContact from 'src/modules/Contact/SimpleContact';

const b = b_.with('new-work');

export default function Work() {
    const [
        globalStore,
    ] = useGlobal();
    const {
        currentCopy,
    } = globalStore;
    const {
        loading,
        error,
        data,
    } = useQuery(PROJECTS);
    const history = useHistory();

    const navigateWorkItem = (url: string) => {
        return history.push(`/work/${url}`);
    }

    if (loading)
        return (
            <section className="caveat-section">
                <div className="caveat-data-loading">
                    <SimpleLogo />
                </div>
            </section>
        );

    if (error)
        return (
            <FullPageSection
                gradTop={colors.pink}
                id="work-section"
            >
                {error.message}
            </FullPageSection>
        );

    const {
        workHeading,
        workSubHeading,
    } = currentCopy;

    const startAnimation = ({currentTarget}: React.MouseEvent<HTMLDivElement, MouseEvent>) => gsap.to(currentTarget, .4, TARGET_ANIMATION);
    const endAnimation = ({currentTarget}: React.MouseEvent<HTMLDivElement, MouseEvent>) => gsap.to(currentTarget, .4, SOURCE_ANIMATION);

    return (
        <section className={b()}>
            <div className={b('content')}>
                <header className={b('header')}>
                    <span className={b('title')}>
                        {workHeading}
                    </span>
                    <span className={b('sub-title')}>
                        {workSubHeading}
                    </span>
                </header>
                <div className={b('items')}>
                    {data.allCaseStudies.map(({
                        projectName,
                        clientName,
                        url,
                        projectPreview,
                    }: any, index: number) => (
                        <div
                            className={b('item')}
                            key={index}
                            onMouseEnter={startAnimation}
                            onMouseLeave={endAnimation}
                            onClick={() => navigateWorkItem(url)}
                        >
                            <div className={b('banner')}>
                                <span className={b('project-name')}>{projectName}</span>
                                <span className={b('client-name')}>{clientName}</span>
                            </div>
                            <img
                                className={b('preview')}
                                src={projectPreview.publicUrl}
                            />
                        </div>
                    ))}
                </div>
                <div className={b('footer')}>
                    <SimpleContact/>
                </div>
            </div>
        </section>
    )
}