// $c-blue:        '#152CDC',
// $c-lightBlue:   '#8A95EE',
// $c-green:       '#00DF8A',
// $c-lightGreen:  '#80EFC4',
// $c-purple:      '#694CFF',
// $c-lightPurple: '#B4A5FF',
// $c-lightPurpleAlpha: '#B4A5FFaa',
// $c-yellow:      '#F8FF02',
// $c-pink:        '#F9B9D1',
// $c-lightPink:   '#FCDCE8',

export const colors = {
  blue: "#152CDC",
  lightBlue: "#8A95EE",
  green: "#00DF8A",
  lightGreen: "#80EFC4",
  purple: "#694CFF",
  lightPurple: "#B4A5FF",
  lightPurpleAlpha: "#B4A5FFaa",
  yellow: "#F8FF02",
  pink: "#F9B9D1",
  lightPink: "#FCDCE8",
}
