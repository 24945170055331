import React, {
    useCallback,
    useEffect,
} from 'react';

export const useOutsideClick = (ref: React.MutableRefObject<HTMLElement>, callback: Function) => {
    const handleClick = useCallback((event: MouseEvent | TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            callback();
        }
    }, [
        callback,
    ]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('touchstart', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('touchstart', handleClick);
        };
    });
};