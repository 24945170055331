import React, { useState, useEffect } from "react";
import { Popover } from 'react-tiny-popover';
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { useQueryParam, DelimitedArrayParam } from 'use-query-params';
import useGlobal from "../../store";
import { ALL_CREATOR_FILTERS, ALL_CREATORS } from "../../queries";
import { SimpleLogo } from "../Graphics";
import Vimeo from "@u-wave/react-vimeo";
import { ArrowSVG } from "../Graphics";
import animations from "./lightboxAnimations";
import { gsap } from "gsap";
import "./styles.scss";

// ************************************************************
// * Talent lightbox Media Component
// ************************************************************
const TalentLightboxMedia = ({ media, onCloseClick }) => {
  useEffect(() => {
    if (media && media.vimeoId) {
      gsap
        .timeline({ autoPlay: true })
        .set(".caveat-item-container .--video", {
          opacity: 0,
        })
        .to(".caveat-item-container .--video", {
          opacity: 1,
          duration: 1,
        });
    } else if (media) {
      gsap
        .timeline({ autoPlay: true })
        .set(".caveat-item-container img", {
          opacity: 0,
        })
        .to(".caveat-item-container img", {
          opacity: 1,
          duration: 1,
        });
    }
  }, [media]);
  if (!media) return <span></span>;
  if (media.vimeoId)
    return (
      <React.Fragment>
        <div className="--video">
          <div
            onClick={onCloseClick}
            className="caveat-lightbox-closebutton material-icons"
          >
            close
          </div>
          <Vimeo
            responsive={true}
            video={media.vimeoId}
            showTitle={false}
            showPortrait={false}
            controls={true}
            loop={false}
          />
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {media.image.publicUrl && (
        <img src={media.image.publicUrl} alt="" />
      )}
      <div
        onClick={onCloseClick}
        className="caveat-lightbox-closebutton material-icons"
      >
        close
      </div>
    </React.Fragment>
  );
};

// ************************************************************
// * Talent lightbox Component
// ************************************************************
let __animation = animations();
let _animation;
const TalentLightbox = ({ creatorProject, onCloseClick }) => {
  const [currentIndex, setCurrentIndex] = useState();
  const [currentMedia, setCurrentMedia] = useState();

  useEffect(() => {
    if (creatorProject && creatorProject.media) {
      setCurrentIndex(0);
      creatorProject.media.map((item) => {
        if (item.image && item.image.publicUrl) {
          new Image().src = item.image.publicUrl;
        }
      });
      _animation = __animation.init(false);
      _animation.intro.play();
    }
  }, [creatorProject]);

  useEffect(() => {
    if (creatorProject && creatorProject.media) {
      setCurrentMedia(creatorProject.media[currentIndex]);
    }
  }, [currentIndex, creatorProject]);

  if (!creatorProject) return <span></span>;

  const onButtonClick = (isBack) => {
    let nextIndex = currentIndex + (isBack ? -1 : 1);
    nextIndex = nextIndex < 0 ? creatorProject.media.length - 1 : nextIndex;
    nextIndex = nextIndex >= creatorProject.media.length ? 0 : nextIndex;
    setCurrentIndex(nextIndex);
  };
  const handleOnClose = () => {
    _animation.hide.eventCallback("onComplete", onCloseClick);
    _animation.hide.play();
    // onCloseClick();
  };

  return (
    <div className="caveat-talent-lightBox">
      {creatorProject &&
        creatorProject.media &&
        creatorProject.media.length > 1 && (
          <div className="caveat-talent-lightbox-navigation">
            <div
              onClick={() => onButtonClick(true)}
              className="caveat-talent-lightbox-button --back"
            >
              <ArrowSVG height={40} />
            </div>
            <div
              onClick={() => onButtonClick(false)}
              className="caveat-talent-lightbox-button --next"
            >
              <ArrowSVG height={40} />
            </div>
          </div>
        )}
      <div className="caveat-item-container">
        <TalentLightboxMedia
          media={currentMedia}
          onCloseClick={handleOnClose}
        />

        <div className="caveat-talent-lightbox-title-lockup">
          <h1>{creatorProject.title}</h1>
          <h3>
            By {creatorProject.creator.firstName}{" "}
            {creatorProject.creator.lastName}
          </h3>
          <ul className="--filters">
            {creatorProject.industries.map(({ _label_ }, i) => {
              return <li key={i}>{_label_}</li>;
            })}
            {creatorProject.mediaTypes.map(({ _label_ }, i) => {
              return <li key={i}>{_label_}</li>;
            })}
            {creatorProject.styles.map(({ _label_ }, i) => {
              return <li key={i}>{_label_}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

// **********************************************************
// * Talent Display Component
// **********************************************************
const TalentDisplay = ({ onTalentClick, onCloseClick }) => {
  // Hooks --------------------------------------------------
  const [{ creatorFilters }] = useGlobal();
  const { loading, error, data } = useQuery(ALL_CREATORS);
  // --------------------------------------------------------
  if (loading)
    return (
      <div className="caveat-talent-output">
        <div className="caveat-data-loading">
          <SimpleLogo />
        </div>
      </div>
    );

  if (error)
    return (
      <div className="caveat-talent-output">
        <div className="ERROR">{error.message}</div>
      </div>
    );

  const { allCreatorProjects } = data;

  // **********************************************************
  // * Get Filtered Creators Method
  // **********************************************************
  const getFilteredCreators = () => {
    const {
      CreativeStyle,
      CreativeIndustry,
      CreativeMediaType,
      Creator
    } = creatorFilters;
    // --------------------------------------------------------
    // Filter Creators Array
    // --------------------------------------------------------

    const filtered = allCreatorProjects.filter((creatorProject) => {
      if (CreativeStyle && creatorProject.styles.length > 0) {
        for (let i in creatorProject.styles) {
          let { id } = creatorProject.styles[i];
          if (CreativeStyle[id] === true) return creatorProject;
        }
      }

      if (CreativeIndustry && creatorProject.industries.length > 0) {
        for (let i in creatorProject.industries) {
          let { id } = creatorProject.industries[i];
          if (CreativeIndustry[id] === true) return creatorProject;
        }
      }

      if (CreativeMediaType && creatorProject.mediaTypes.length > 0) {
        for (let i in creatorProject.mediaTypes) {
          let { id } = creatorProject.mediaTypes[i];
          if (CreativeMediaType[id] === true) return creatorProject;
        }
      }

      if (Creator &&
          creatorProject.creator &&
          Creator[creatorProject.creator.id] === true) {
        return creatorProject;
      }

      return null;
    });
    // --------------------------------------------------------
    // Return Creators
    // --------------------------------------------------------
    // if (filtered.length < 1) return <div>no results</div>;
    if (filtered.length < 1)
      return allCreatorProjects.map((creatorProject, index) => {
        return (
          <CreatorProject
            key={index}
            onCloseClick={onCloseClick}
            onTalentClick={onTalentClick}
            creatorProject={creatorProject}
          />
        );
      });
    return filtered.map((creatorProject, index) => {
      return (
        <CreatorProject
          key={index}
          onCloseClick={onCloseClick}
          onTalentClick={onTalentClick}
          creatorProject={creatorProject}
        />
      );
    });
  };
  // ----------------------------------------------------------
  // Render Creators
  // ----------------------------------------------------------
  return <div className="caveat-talent-output">{getFilteredCreators()}</div>;
};

// ************************************************************
// * CreatorProject Component
// ************************************************************
const CreatorProject = ({ creatorProject, onTalentClick }) => {
  CreatorProject.propTypes = {
    creatorProject: PropTypes.object,
  };
  let { publicUrl } = creatorProject.thumbnail;
  return (
    <div
      className="caveat-creatorProject-container"
      onClick={() => onTalentClick(creatorProject)}
    >
      <div className="caveat-creatorProject-preview-container">
        <img src={publicUrl} alt={creatorProject.name} />
      </div>
      <div className="caveat-creatorProject-title-lockup">
        <strong>{`${creatorProject.title}`}</strong>
        {creatorProject &&
          creatorProject.creator &&
          `by ${creatorProject.creator.firstName} ${creatorProject.creator.lastName}`}
      </div>
    </div>
  );
};

// ************************************************************
// Filer Section Component
// ************************************************************
const FilterSection = ({ data, title, type }) => {
  FilterSection.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    type: PropTypes.string,
  };
  const [globalState] = useGlobal();
  const [isFilterPopoverOpen, setFilterPopoverOpen] = useState(false);

  const onFilterPopoverOutsideClick = () => {
    setFilterPopoverOpen(false);
  };

  const getSelectedFiltersCount = () => {
    return globalState.creatorFilters && globalState.creatorFilters[type] ? 
           Object.values(globalState.creatorFilters[type]).filter(value => value).length : 0;
  };

  const filters = data.map(({ _label_, __typename, id }, index) => (
    <Filter
      key={index}
      value={id}
      type={__typename}
      className="caveat-filter-group"
      label={_label_}
    />
  ));
  return (
    <div className="caveat-filter-group-container">
      <Popover
        onClickOutside={onFilterPopoverOutsideClick}
        isOpen={isFilterPopoverOpen}
        positions={['bottom']}
        containerClassName="caveat-talent-filters-popover-container"
        content={({ childRect }) => {
          return (<div className="caveat-talent-filters-popover" style={{width: `${childRect.width}px`}}>{filters}</div>);
        }}
      >
        <div onClick={() => setFilterPopoverOpen(!isFilterPopoverOpen)}>
          <h4 className={isFilterPopoverOpen ? 'active' : ''}>
            {title}
            {!!getSelectedFiltersCount() &&
              ` (${getSelectedFiltersCount()})`
            }
          </h4>
          {isFilterPopoverOpen && <div className="caveat-filter-group-popover-opened"></div>}
          {!isFilterPopoverOpen && <div className="caveat-filter-group-popover-closed"></div>}
        </div>
      </Popover>
    </div>
  );
};

// ************************************************************
// * Filer Component
// ************************************************************
const Filter = ({ label, type, value, className }) => {
  Filter.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
  };
  const [globalState, globalActions] = useGlobal();
  const [talentQuery, setTalentQuery] = useQueryParam('talents', DelimitedArrayParam);

  const checkBoxClick = ({ target }) => {
    const { checked, value, name } = target;
    globalActions.setCreatorFilters({ checked, value, name });

    if (type === 'Creator') {
      const talentFilterIds = Object.keys(globalState.creatorFilters[name])
                              .filter(talentId => !!globalState.creatorFilters[name][talentId]);
      setTalentQuery(talentFilterIds.length ? talentFilterIds : undefined);
    }
  };

  const isChecked = () => {
    if (
      globalState.creatorFilters &&
      globalState.creatorFilters[type] &&
      globalState.creatorFilters[type][value] &&
      globalState.creatorFilters[type][value] === true
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={className}>
      <input
        onChange={checkBoxClick}
        checked={isChecked()}
        type="checkbox"
        id={value}
        name={type}
        value={value}
      />
      <label htmlFor={value}>{label}</label>
    </div>
  );
};

// ************************************************************
// * Talent Component
// ************************************************************
const Talent = () => {
  const [{ currentCopy, creatorFilters }, { setCreatorFilters, clearCreatorFilters }] = useGlobal();
  const { loading, error, data } = useQuery(ALL_CREATOR_FILTERS);
  const [currentProject, setCurrentProject] = useState();
  const [talentQuery, setTalentQuery] = useQueryParam('talents', DelimitedArrayParam);

  useEffect(() => {
    if (talentQuery) {
      talentQuery.forEach(talentId => {
        setCreatorFilters({ checked: true, name: 'Creator', value: talentId });
      });
    }
  }, []);

  if (loading)
    return (
      <section id="talent-section" className="caveat-section">
        <div className="caveat-data-loading">
          <SimpleLogo />
        </div>
      </section>
    );

  if (error)
    return (
      <section id="talent-section" className="caveat-section">
        <div className="ERROR">{error.message}</div>
      </section>
    );

  const { talentHeading, talentSubHeading } = currentCopy;
  const {
    allCreativeMediaTypes,
    allCreativeIndustries,
    allCreativeStyles,
  } = data;
  const allCreators = data.allCreators.map(creator => ({_label_: `${creator.firstName} ${creator.lastName}`, ...creator}));

  const onTalentClick = (project) => {
    setCurrentProject(project);
  };

  const onCloseClick = () => {
    setCurrentProject(null);
  };

  const isAnyFilterActive = () => {
    if (!Object.keys(creatorFilters).length) {
      return false;
    } else {
      return Object.keys(creatorFilters).some(type => Object.values(creatorFilters[type]).filter(value => value).length > 0);
    }
  }

  const onClearFiltersClick = () => {
    setTalentQuery(undefined);
    clearCreatorFilters();
  };

  return (
    <React.Fragment>
      <div id="section-mask"></div>
      <TalentLightbox
        creatorProject={currentProject}
        onCloseClick={onCloseClick}
      />
      <section id="talent-section" className="caveat-section">
        <div className="caveat-talent-title-lockup">
          <h1>{talentHeading}</h1>
          <h2>{talentSubHeading}</h2>
        </div>
        <div className="caveat-talent-filters-title">
          <span className="caveat-talent-filters-title-item">FILTER BY:</span>
          {isAnyFilterActive() && 
            <span className="caveat-talent-filters-title-item clear-filters" onClick={onClearFiltersClick}>
              CLEAR ALL ×
            </span>
          }
        </div>
        <div className="caveat-talent-filters">
          <FilterSection data={allCreativeIndustries} title="Industries" type="CreativeIndustry"/>
          <FilterSection data={allCreativeMediaTypes} title="Media" type="CreativeMediaType"/>
          <FilterSection data={allCreativeStyles} title="Styles" type="CreativeStyle"/>
          <FilterSection data={allCreators} title="Talent" type="Creator"/>
        </div>
        <TalentDisplay onTalentClick={onTalentClick} />
      </section>
    </React.Fragment>
  );
};
export default Talent;
// ************************************************************
// ************************************************************
// ************************************************************
