const SOURCE_PATTERN = 'radial-gradient(#152CDC 15px, transparent 15px), radial-gradient(#152CDC 15px, transparent 15px)';
const TARGET_PATTERN = 'radial-gradient(#152CDC 1.5px, transparent 1.5px), radial-gradient(#152CDC 1.5px, transparent 1.5px)';

export const TARGET_ANIMATION = {
    '--pattern-animation': TARGET_PATTERN,
    '--opacity-animation': 1,
};
export const SOURCE_ANIMATION = {
    '--pattern-animation': SOURCE_PATTERN,
    '--opacity-animation': '.25',
};