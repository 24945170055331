import './CaseStudy.scss';

import React from 'react';
import {
    useParams,
    Link,
} from 'react-router-dom';
import {
    useQuery,
} from '@apollo/client';
import b_ from 'b_';
import Vimeo from '@u-wave/react-vimeo';
import Gallery from 'react-photo-gallery';

import useGlobal from 'src/store';

import {
    PROJECT_BY_URL,
} from 'src/queries';
import {
    colors,
} from 'src/vars';

import {
    SimpleLogo,
} from 'src/modules/Graphics';
import FullPageSection from 'src/modules/FullPageSection';
import SidePanel from './components/SidePanel/SidePanel';
import Pagination from './components/Pagination/Pagination';
import {
    useAnimations,
} from './animation';

const b = b_.with('new-case-study');

const MOBILE_MARGIN = 4;
const DESKTOP_MARGIN = 8;
const DEFAULT_MOBILE_MAX_IMAGES_PER_ROW = 1;
const DEFAULT_DESKTOP_MAX_IMAGES_PER_ROW = 8;

const renderGridImage = (props: any) => (
    <div
        key={props.index}
        className={b('image')}
        style={{
            margin: props.margin,
            height: props.photo.height,
            width: props.photo.width,
            backgroundImage: `url("${props.photo.src.trim()}")`
        }}
    />
);

interface IProps {
    caseStudy: any;
}

function CaseStudy({
    caseStudy,
}: IProps) {
    const [{
        isMobile,
    }] = useGlobal();

    useAnimations(caseStudy);

    const lastIndex = caseStudy.projectName.lastIndexOf(' ') + 1;
    const firstPart = caseStudy.projectName.substring(0, lastIndex) + ' ';
    const lastPart = caseStudy.projectName.substring(lastIndex);

    const {
        projectType,
        clientCategory,
        contentBlocks,
        year,
        isSidePanelVisible,
        sidePanelTitle,
        sidePanelDescription,
    } = caseStudy;
    const tags = [
        ...projectType.map(({type}: any) => type),
        ...clientCategory.map(({type}: any) => type),
        year,
    ].join(', ');

    return (
        <section className={b()}>
            <Link to='/work'>
                <span className={b('back')}>
                    BACK
                </span>
            </Link>
            {isSidePanelVisible && (
                <SidePanel
                    title={sidePanelTitle}
                    description={sidePanelDescription}
                    tags={tags}
                />
            )}
            <div className={b('content')}>
                <header className={b('header')}>
                    <span className={b('title')}>
                        <span>{firstPart}</span>
                        <div className={b('rest-title')}>
                            <span className={b('last-word')}>{lastPart}</span>
                            <span className={b('bean')}/>
                        </div>
                    </span>
                    <span className={b('sub-title')}>
                        {caseStudy.subHead}
                    </span>
                </header>
                <div className={b('items')}>
                    {contentBlocks.map((block: any, i: number) => {
                        const {
                            vimeoId,
                            images,

                            // with media + standalone
                            largeHeadline,
                            // with media only
                            bodyCopy,

                            // standalone only
                            mediumHeadline,
                            quote,
                        } = block;

                        const isVideo = Boolean(vimeoId);
                        const isImages = Boolean(images?.length) && !isVideo; // todo: Boolean(images?.length)
                        const hasMedia = isVideo || isImages;
                        const isQuote = Boolean(quote);
                        const isMediumHeadline = Boolean(mediumHeadline);

                        return (
                            <div
                                className={b('item', {
                                    video: isVideo,
                                    images: isImages,
                                    quote: isQuote,
                                    'medium-headline': isMediumHeadline,
                                })}
                                key={i}
                            >
                                <div className={b('media-block')}>
                                    <div className={b('under')}/>
                                    {isVideo && (
                                        <div className={b('video')}>
                                            <Vimeo
                                                responsive
                                                video={block.vimeoId}
                                                showTitle={false}
                                                showPortrait={false}
                                                controls
                                                loop={false}
                                            />
                                        </div>
                                    )}
                                    {isImages && (
                                        <Gallery
                                            margin={isMobile ? MOBILE_MARGIN : DESKTOP_MARGIN}
                                            limitNodeSearch={isMobile ? DEFAULT_MOBILE_MAX_IMAGES_PER_ROW : block.imagesPerRow || DEFAULT_DESKTOP_MAX_IMAGES_PER_ROW}
                                            renderImage={renderGridImage}
                                            photos={block.images.map((media: any) => ({
                                                src: media.image.publicUrl,
                                                width: media.width || 1,
                                                height: media.height || 1
                                            }))}
                                        />
                                    )}
                                    {isMediumHeadline && (
                                        <div className={b('medium-headline')}>
                                            {mediumHeadline}
                                        </div>
                                    )}
                                </div>
                                {(largeHeadline || bodyCopy) && (
                                    <div className={b('info-block', {
                                        'large-headline': Boolean(largeHeadline),
                                        'body-copy': Boolean(bodyCopy),
                                        'has-media': hasMedia,
                                    })}>
                                        {largeHeadline && (
                                            <span className={b('large-headline')}>
                                                {largeHeadline}
                                            </span>
                                        )}
                                        {bodyCopy && (
                                            <span className={b('body-copy')}>
                                                {bodyCopy}
                                            </span>
                                        )}
                                    </div>
                                )}
                                {isQuote && (
                                    <div className={b('quote')}>
                                        {quote}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <footer className={b('footer')}>
                    <Pagination/>
                </footer>
            </div>
        </section>
    )
}

export default function CaseStudyWrapper() {
    const {
        url,
    } = useParams();
    const {
        loading,
        error,
        data,
    } = useQuery(PROJECT_BY_URL, {
        variables: {
            url,
        },
    });

    if (loading)
        return (
            <section className="caveat-section">
                <div className="caveat-data-loading">
                    <SimpleLogo />
                </div>
            </section>
        );

    if (error)
        return (
            <FullPageSection
                gradTop={colors.pink}
                id="work-section"
            >
                {error.message}
            </FullPageSection>
        );

    const [
        caseStudy,
    ] = data.allCaseStudies;

    return (
        <CaseStudy
            key={url}
            caseStudy={caseStudy}
        />
    );
}