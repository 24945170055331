export const setCreatorFilters = (store, { name, value, checked }) => {
  const creatorFilters = store.state.creatorFilters;
  if (!creatorFilters[name]) {
    creatorFilters[name] = {
      [value]: checked,
    };
  } else if (!creatorFilters[name][value]) {
    creatorFilters[name][value] = checked;
  } else {
    creatorFilters[name][value] = checked;
  }
  store.setState({ creatorFilters });
};

export const clearCreatorFilters = (store) => {
  store.setState({ creatorFilters: {} });
};

export const setIsMobile = (store, isMobile) => {
  if (store.state.isMobile !== isMobile) {
    store.setState({ isMobile: isMobile });
  }
};

const combineCopy = (copy, fallback) => {
  const nextCopy = {
    ...copy,
  };

  for (let [key, value] of Object.entries(fallback)) {
    if (!nextCopy[key]) {
      nextCopy[key] = value;
    }
  }
  return nextCopy;
};

export const setCurrentCopy = (store, { copy, fallback }) => {
  if (store.state.copy !== copy) {
    store.setState({ copy: copy });
    store.setState({ currentCopy: combineCopy(copy, fallback) });
  }
};

export const setFirstLoad = (store, firstLoad) => {
  store.setState({ firstLoad: firstLoad });
};

export const setSiteTransitionState = (store, state) => {
  store.setState({ siteTransitionState: state });
};

export const setTransitionTarget = (store, url) => {
  if (url !== store.state.transitionTarget) {
    store.setState({ transitionTarget: url });
  }
};

export const setCurrentWorkPage = (store, currentWorkPage) => {
  if (currentWorkPage !== store.state.currentWorkPage) {
    store.setState({ currentWorkPage: currentWorkPage });
  }
};

export const setCurrentCaseStudyIndex = (store, currentCaseStudyIndex) => {
  if (store.state.currentCaseStudyIndex !== currentCaseStudyIndex) {
    store.setState({ currentCaseStudyIndex: currentCaseStudyIndex });
  }
};

export const set = (store, currentCopy) => {
  if (store.state.currentCopy !== currentCopy) {
    store.setState({ currentCopy: currentCopy });
  }
};
