import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useGlobal from "../../store";
import { useQuery } from "@apollo/client";
import { ALL_NEWS_ITEMS } from "../../queries";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ArrowSVG } from "../Graphics";
import { colors } from "../../vars";
import { getFullPath } from "../../utils";
import { SimpleLogo } from "../Graphics";
import Vimeo from "@u-wave/react-vimeo";

import "./styles.scss";

let startY = 0;
let _tl;
const STATE_LANDING = 0;
const STATE_ANIMATING = 1;
const STATE_SHOWING_MORE = 2;

let state = STATE_LANDING;

const Landing = () => {
  const [{ isMobile, currentCopy }] = useGlobal();
  const { loading, error, data } = useQuery(ALL_NEWS_ITEMS);
  //const titleRef = useRef(null);
  //const subTitleRef = useRef(null);
  let section;

  // const calculateElementTextLines = (element) => {
  //   return element.offsetHeight / parseInt(window.getComputedStyle(element).lineHeight.replace('px', ''));
  // };

  // const calculateTitleSize = () => {
  //   const titleEl = titleRef.current;
  //   const subTitleEl = subTitleRef.current;
  //   let fontSize = 8;

  //   titleEl.style.fontSize = fontSize + 'vw';
  //   subTitleEl.style.fontSize = fontSize + 'vw';
  //   titleEl.style.letterSpacing = '-0.11vw';
  //   subTitleEl.style.letterSpacing = '-0.11vw';

  //   let titleLines = calculateElementTextLines(titleEl);
  //   let subTitleLines = calculateElementTextLines(subTitleEl);

  //   if (titleLines > 1 || subTitleLines > 1) {
  //     while (titleLines > 1 || subTitleLines > 1) {
  //       fontSize -= 0.1;

  //       titleEl.style.fontSize = fontSize + 'vw';
  //       subTitleEl.style.fontSize = fontSize + 'vw';
  //       titleEl.style.letterSpacing = (-fontSize / 80) + 'vw';
  //       subTitleEl.style.letterSpacing = (-fontSize / 80) + 'vw';

  //       titleLines = calculateElementTextLines(titleEl);
  //       subTitleLines = calculateElementTextLines(subTitleEl);
  //     }
  //   }
  // };

  const getScreenY = (e) => {
    if (e.screenY) return e.screenY;
    if (e.touches[0]) return e.touches[0].screenY;
    return null;
  };
  const getChangedY = (e) => {
    if (e.screenY) return e.screenY;
    if (e.changedTouches[0]) return e.changedTouches[0].screenY;
  };
  const onTouchStart = (e) => (startY = getScreenY(e));
  const onTouchEnd = (e) => evalState(startY > getChangedY(e));

  // useEffect(() => {
  //   window.addEventListener('resize', calculateTitleSize, { passive: true });
  //   return () => {
  //     window.removeEventListener('resize', calculateTitleSize);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (titleRef.current && subTitleRef.current) {
  //     calculateTitleSize();
  //   }
  // }, [loading]);

  useEffect(() => {
    state = STATE_LANDING;
    if (_tl) {
      gsap.set(
        `
          #landing-title,
          #landing-section,#landing-menu,
          #landing-img-vid,
          #landing-img-vid video,
          #landing-more-indicator,
          #landing-more-indicator-arrow,
          .circs,
          #landing-showmore-title,
          #landing-showmore-copy,
          #landing-showmore-image,
          #landing-showmore-image img,
          #landing-backup-container,
          #caveat-back-up-arrow`,
        {
          clearProps: "all",
        }
      );

      _tl.kill();
      _tl = undefined;

      document
        .getElementById("landing-more-indicator-arrow")
        .setAttribute("viewBox", "0 0 100 70");
    }
  }, [isMobile]);

  useEffect(() => {
    if (!section) return;

    section.addEventListener("touchstart", onTouchStart, { passive: true });
    section.addEventListener("touchend", onTouchEnd, { passive: true });
    section.addEventListener("mousedown", onTouchStart, { passive: true });
    section.addEventListener("mouseup", onTouchEnd, { passive: true });
    section.addEventListener("wheel", onWheel, { passive: true });

    return () => {
      if (section) {
        section.removeEventListener("wheel", onWheel, { passive: true });
        section.removeEventListener("touchstart", onTouchStart, {
          passive: true,
        });
        section.removeEventListener("touchend", onTouchEnd, { passive: true });
        section.removeEventListener("mousedown", onTouchStart, {
          passive: true,
        });
        section.removeEventListener("mouseup", onTouchEnd, { passive: true });
      }
      document
        .getElementById("naviation-logo")
        .removeEventListener("touchend", enterShowLandingAnimation, {
          passive: true,
        });
      document
        .getElementById("naviation-logo")
        .removeEventListener("click", enterShowLandingAnimation, {
          passive: true,
        });
    };
  });

  if (loading)
    return (
      <section className="caveat-section">
        <div className="caveat-data-loading">
          <SimpleLogo />
        </div>{" "}
      </section>
    );
  if (error)
    return (
      <section id="landing-section" className="caveat-section">
        <div className="ERROR">{error.message}</div>{" "}
      </section>
    );

  let {
    landingHeading,
    landingSubHeading,
    navigationWorkCTA,
    navigationTalentCTA,
    navigationContactCTA,
    landingVideo,
  } = currentCopy;

  const evalState = (_down) => {
    switch (state) {
      case STATE_ANIMATING:
        break;
      case STATE_LANDING:
        if (_down) enterShowMoreAnimation();
        break;
      case STATE_SHOWING_MORE:
        if (!_down) enterShowLandingAnimation();
        break;
      default:
        state = STATE_LANDING;
        break;
    }
  };

  const getTimeLine = () => {
    if (_tl === undefined) {
      _tl = gsap.timeline({
        autoPlay: false,
      });

      _tl
        .to("#landing-title", {
          top: "-40vh",
          bottom: "unset",
          duration: 1,
          ease: "expo.inOut",
        })
        .to(
          "#landing-section",
          {
            backgroundImage: `linear-gradient(white, white)`,
            duration: 1,
          },
          "-=1"
        )

        .to(
          "#landing-menu",
          {
            top: "-50vh",
            scale: 0.8,
            duration: 1,
            ease: "expo.inOut",
          },
          "-=.9"
        )

        .to(
          "#landing-img-vid",
          {
            top: "-45vh",
            duration: 1.5,
            scale: 0.7,
            clipPath: "circle(0%)",
            ease: "expo.inOut",
          },
          "-=1.2"
        )
        .to(
          "#landing-img-vid video",
          {
            borderTopWidth: "100vw",
            duration: 0.2,
            ease: "expo.in",
          },
          "-=1.2"
        )
        .to(
          "#landing-more-indicator",
          {
            top: "110vh",
            duration: 0.3,
            ease: "expo.in",
          },
          "-=.9"
        )

        .to(
          "#landing-more-indicator-arrow",
          {
            top: "120vh",
            attr: { viewBox: "0 0 100 300" },
            duration: 1,
            ease: "expo.in",
          },
          "-=1.8"
        );
      if (!isMobile) {
        _tl
          .to(
            ".circs",
            {
              height: "80vh",
              stagger: 0.1,
              duration: 0.5,
              ease: "expo.out",
            },
            "-=.6"
          )
          .to(
            "#landing-showmore-title",
            {
              clipPath: "inset(10% 0 0 0%)",
              borderRightWidth: 0,
              duration: 1.2,
              ease: "expo.inOut",
            },
            "-=.8"
          )

          .to(
            "#landing-showmore-copy",
            {
              clipPath: "inset(0% 0% 0% 0%)",
              duration: 0.4,
              ease: "expo.out",
            },
            "-=.4"
          )

          .to(
            "#landing-showmore-image",
            {
              clipPath: "inset(0% 0% 0% 0%)",
              duration: 0.2,
              ease: "expo.out",
            },
            "-=.2"
          )

          .to(
            "#landing-showmore-image img",
            {
              borderLeftWidth: 0,
              duration: 0.2,
              ease: "expo.out",
            },
            "-=.2"
          )
          .to(
            "#landing-backup-container",
            {
              top: "11vmin",
              duration: 0.2,
              ease: "expo.out",
            },
            "-=.2"
          )
          .to(
            "#caveat-back-up-arrow",
            {
              attr: { viewBox: "0 0 100 60" },
              duration: 1,
              ease: "expo.out",
            },
            "-=.2"
          );
      } else {
        console.log("mobile animation");
        _tl
          .to(
            ".circs",
            {
              height: "79%",
              stagger: 0.1,
              duration: 0.5,
              ease: "expo.out",
            },
            "-=.6"
          )
          .to(
            "#landing-showmore-title",
            {
              clipPath: "inset(10% 0 0 0%)",
              borderRightWidth: 0,
              duration: 1.2,
              ease: "expo.inOut",
            },
            "-=.8"
          )
          .to(
            "#landing-showmore-copy",
            {
              clipPath: "inset(0% 0% 0% 0%)",
              duration: 0.4,
              ease: "expo.out",
            },
            "-=.4"
          )
          .to(
            "#landing-showmore-image",
            {
              clipPath: "inset(0% 0% 0% 0%)",
              duration: 0.2,
              ease: "expo.out",
            },
            "-=.2"
          )
          .to(
            "#landing-showmore-image img",
            {
              borderLeftWidth: 0,
              duration: 0.2,
              ease: "expo.out",
            },
            "-=.2"
          );
      }
      // }
    }
    return _tl;
  };

  const enterShowMoreAnimation = () => {
    state = STATE_ANIMATING;
    let t = getTimeLine();
    t.eventCallback("onComplete", () => {
      state = STATE_SHOWING_MORE;
    });
    t.timeScale(1);
    t.restart();
    document
      .getElementById("naviation-logo")
      .addEventListener("touchend", enterShowLandingAnimation, {
        passive: true,
      });
    document
      .getElementById("naviation-logo")
      .addEventListener("click", enterShowLandingAnimation, {
        passive: true,
      });
  };

  const enterShowLandingAnimation = () => {
    state = STATE_ANIMATING;
    let t = getTimeLine();
    t.eventCallback("onReverseComplete", () => {
      state = STATE_LANDING;
    });
    t.timeScale(1.8);
    t.restart();
    t.reverse(0);
  };

  const evalTrigger = (_dy, _cb, ...props) => {
    if (Math.abs(_dy) > 30) _cb(...props);
  };

  const onWheel = (e) => {
    let { deltaY } = e;
    evalTrigger(deltaY, evalState, deltaY > 0);
  };

  // ------------------------------
  const date = new Date();
  // ------------------------------
  let currentNews;
  let evalDate = new Date(0);

  data.allNewsItems.map((news) => {
    let mDate = new Date(news.publishDate);
    if (mDate.getTime() < date.getTime()) {
      if (mDate.getTime() > evalDate.getTime()) {
        currentNews = news;
        evalDate = mDate;
        return currentNews;
      }
    }
    return false;
  });

  return (
    <section
      ref={(element) => (section = element)}
      id="landing-section"
      className="caveat-section"
    >
      <div
        onClick={enterShowLandingAnimation}
        id="landing-back-up-hidden"
      ></div>
      <div id="landing-title" className="caveat-title-lockup">
        <h1>{landingHeading}</h1>
        <h1>{landingSubHeading}</h1>
      </div>
      <div id="landing-img-vid" className="caveat-image-container">
        <video className="caveat-video-player" autoPlay loop muted playsInline>
          {landingVideo && <source src={landingVideo.publicUrl} />}
          Your browser does not support the video tag.
        </video>
      </div>
      <div id="landing-menu" className="caveat-landing-menu-container">
        <ul>
          <li>
            <Link to="/work">{navigationWorkCTA}</Link>
          </li>
          <li>
            <Link to="/talent">{navigationTalentCTA}</Link>
          </li>
          <li>
            <Link to="/contact">{navigationContactCTA}</Link>
          </li>
        </ul>
      </div>
      {[colors.lightBlue, "white", colors.lightGreen, "white"].map((v, i) => {
        return (
          <div key={i} className="circs" style={{ backgroundColor: v }}></div>
        );
      })}

      <ArrowSVG id="landing-more-indicator-arrow" height={50} />

      <div
        onClick={enterShowMoreAnimation}
        id="landing-more-indicator"
        className="caveat-more-indicator"
      >
        <h3>{currentNews.landingCurrentCTA}</h3>
      </div>

      <div onClick={enterShowLandingAnimation} id="landing-backup-container">
        <h2>Back Up</h2>
        <ArrowSVG id="caveat-back-up-arrow" height={100} />
      </div>

      <div id="landing-showmore-title">
        <h1>{currentNews.landingCurrentHeading}</h1>
      </div>
      <NewsSection currentNews={currentNews} />
    </section>
  );
};
export default Landing;

const NewsSection = ({ currentNews }) => {
  NewsSection.propTypes = {
    currentNews: PropTypes.object,
  };

  console.log('NEWS: ', currentNews)

  const returnMedia = () => {
    if (currentNews.vimeoId) {
      return (
        <div id="landing-showmore-image" className="--video">
          <Vimeo
            responsive={true}
            video={currentNews.vimeoId}
            showTitle={false}
            showPortrait={false}
            controls={true}
            // background={true}
            loop={false}
          />
        </div>
      );
    } else if (currentNews.media) {
      return (
        <div id="landing-showmore-image">
          {currentNews.url && (
            <a href={currentNews.url} target="_blank" rel="noreferrer">
              <img
                src={currentNews.media.publicUrl}
                alt={currentNews.headline}
              />
            </a>
          )}
          {!currentNews.url && (
            <img
              src={currentNews.media.publicUrl}
              alt={currentNews.headline}
            />
          )}
        </div>
      );
    }
    return <span></span>;
  };
  return (
    <React.Fragment>
      <div id="landing-showmore-copy">
        <h2>{currentNews.headline}</h2>
        <p>{currentNews.bodyCopy}</p>
        {currentNews.url && (
          <a href={currentNews.url} target="_blank" rel="noreferrer">{currentNews.urlText || currentNews.url}</a>
        )}
      </div>
      {returnMedia()}
    </React.Fragment>
  );
};
